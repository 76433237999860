/* Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
SPDX-License-Identifier: Apache-2.0*/

body {
  margin: 0;
  padding: 0;
  font-family: ClearSans, sans-serif;
}

h1, h2, h3, h4, h5 {
  line-height: initial
}

#root {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.fixfloat:after {
  content: "";
  display: table;
  clear: both;
}

@keyframes App-logo-spin {
  from { transform: scale(1); }
  to { transform: scale(1.08); }
}

/* fix semantic-ui quirks */
.ui.negative.floating.message, .ui.attached.negative.floating.message {
  box-shadow: 
    0px 0px 0px 1px #E0B4B4 inset, 
    0px 2px 4px 0px rgba(34, 36, 38, 0.12), 
    0px 2px 10px 0px rgba(34, 36, 38, 0.15)
}

@font-face {
  font-family: 'ClearSans';
  src: url('./ClearSans-Regular.woff2') format('woff2');
}

/* Semantic UI react search bar styling */
.ui.search > .ui.input.icon { 
  width: 20vw; 
}