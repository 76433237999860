div.ui.borderless.inverted.attached.menu {
  background-color: #194036;
  height: 100px;
  border-top-style: solid;
  border-top-width: 20px;
  border-top-color: #ffc51d;
}

#root > div.ui.borderless.inverted.attached.menu > a:nth-child(1) > img {
  width: 140px;
}

#root > div.ui.borderless.inverted.attached.menu {
  font-family: ClearSans, sans-serif;
}